import React from "react"

import Job from "../components/job"
import { JobFeature, JobRole } from "../components/job-components"

const JobPerformanceEngineer = () => {
  return (
    <Job
      title="Performance Engineer for a highly scalable ML product"
      location="Hybrid Office in Linz; Remote in Austria"
      workingTime="Full-time/Part-time"
      startDate="Starting ASAP"
      annualSalary="EUR 49,000"
    >
      <h3 className="my-5">Engineering at Celantur</h3>
      <JobFeature>
        Processing text information is nice, but have you ever built a scalable
        system that works with peak loads of Terabytes of image and video data?
        – We do that.
      </JobFeature>
      <JobFeature>
        While processing millions of files and objects, every millisecond
        counts. By solving small but hard algorithmic challenges, we can reap
        big performance rewards.{" "}
      </JobFeature>
      <JobFeature>
        As a growing startup, everyone has to take over responsibilities. Your
        initiative spirit will be a major contribution to the team’s success!
      </JobFeature>
      <JobFeature>
        We speak many languages, but our favourites are Python (backend) and
        React (frontend). We also use AWS managed services and Docker
        containers.
      </JobFeature>
      <JobFeature>
        We have a highly experienced Machine Learning Team and you can expect to
        work with and learn together from our Jedi Masters.
      </JobFeature>
      <JobFeature>
        Our customers are world-class companies, therefore we’re exposed to a
        lot of exciting and highly technical use-cases in Reality Capture,
        Construction, Automotive and Big Data.
      </JobFeature>
      <JobFeature>
        As a team, we share a variety of interests: chess, books, technology,
        stock markets, just to name a few. For this reason, we leave dedicated
        and spontaneous moments to discuss, exchange learnings and of course,
        have fun!
      </JobFeature>

      <JobRole>
        <JobFeature>
          You are polyglottal in C/C++, CUDA, Python and you program close to
          metal.
        </JobFeature>
        <JobFeature>
          You know the time and space complexities of each function you write.
        </JobFeature>
        <JobFeature>
          You make complex code run faster while keeping it clean and readable.
        </JobFeature>
        <JobFeature>
          You know the strengths and weaknesses of different CPU and GPU
          architectures.
        </JobFeature>
        <JobFeature>
          Thorough knowledge about vectorization and broadcasting, as well as
          parallelization
        </JobFeature>
        <JobFeature>
          You work in a technically diverse team with full-stack developers and
          machine learning engineers.
        </JobFeature>
      </JobRole>

      <h3 className="my-5">What we offer</h3>
      <JobFeature>
        Hybrid working policy (office presence and home office){" "}
      </JobFeature>
      <JobFeature>
        Short feedback loops and career opportunities as part of a growing
        startup
      </JobFeature>
      <JobFeature>
        An office at Tabakfabrik Linz - one of the hearts of Austria’s start-up
        scene - with a diverse set of great places for lunch and leisure (e.g.
        Donaulände)
      </JobFeature>
      <JobFeature>
        Work with our young and international team on high-tech solutions and
        services
      </JobFeature>
      <JobFeature>An office at Tabakfabrik Linz - one of the hearts of Austria’s start-up
        scene - with a diverse set of great places for lunch and leisure (e.g.
        Donaulände)</JobFeature>
    </Job>
  )
}

export default JobPerformanceEngineer
